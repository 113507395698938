import { callIdentityServerApi } from "../../../ApiGateway";
import { AppThunkAction } from "../../index";
import { RequestState } from "../../sharedTypes";
import { KnownAction } from "./actions";
import { ClientSearchSearchResponse } from "./state";
import SearchRequest from "../../search";

export const actionCreators = {
    /**
     * Search clients.
     */
    searchClients: (request: SearchRequest): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (!appState.clientsList) {
            console.error("Clients list state is not initialized.");
            return;
        }

        const state = appState.clientsList;
        if (state.searchRequestState === RequestState.InProgress) {
            console.error("Search request is already in progress.");
            return;
        }

        if (!state.searchRequest) {
            console.error("Search request is not initialized.");
            return;
        }

        let url = `api/clients/list?page=${request.page.number + 1}&pageSize=${request.page.size}`;
        const query = (request.query || "").trim();
        if (query) {
            url += `&searchText=${encodeURIComponent(query)}`;
        }

        callIdentityServerApi<{}, ClientSearchSearchResponse>(url)
            .then(response => {
                dispatch({ type: "RECEIVE_SEARCH_CLIENTS", searchResult: {
                    items: response.clients,
                    total: response.totalCount
                } });
            })
            .catch(() => dispatch({ type: "REJECT_SEARCH_CLIENTS" }));
        dispatch({ type: "REQUEST_SEARCH_CLIENTS", request: state.searchRequest });
    },

    /**
     * Delete client.
     */
    deleteClient: (id: number): AppThunkAction<KnownAction> => (dispatch, _) => {
        callIdentityServerApi<{}, {}>(`api/clients/${id}`, "DELETE")
            .then(() => dispatch({ type: "RECEIVE_DELETE_CLIENT", id }))
            .catch(() => dispatch({ type: "REJECT_DELETE_CLIENT" }));
        dispatch({ type: "REQUEST_DELETE_CLIENT", id });
    }
};

