import { Action, Reducer } from "redux";
import { RequestState } from "../../sharedTypes";
import { KnownAction } from "./actions";
import { ClientSearchSearchResponse, ClientSearchState, ClientListItem } from "./state";
import { FilterClauseType, SearchResponse } from "../../search";

const unloadedState: ClientSearchState = {
    searchRequestState: RequestState.NotStarted,
    deleteRequestState: RequestState.NotStarted,
    deleteError: undefined,
    searchResult: undefined,
    searchRequest: {
        query: "",
        filter: {
            terms: [],
            clause: FilterClauseType.And,
        },
        order: {
            terms: [],
        },
        page: {
            size: 10,
            number: 0,
        },
    }
};

export const reducer: Reducer<ClientSearchState> = (state: ClientSearchState | undefined, incomingAction: Action): ClientSearchState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        // Search
        case "CHANGE_CLIENTS_SEARCH_REQUEST":
            return {
                ...state,
                searchRequest: Object.assign(state.searchRequest!, action.request),
            };
        case "REQUEST_SEARCH_CLIENTS":
            return {
                ...state,
                searchRequest: action.request,
                searchRequestState: RequestState.InProgress,
                deleteRequestState: RequestState.NotStarted,
                searchResult: undefined
            };
        case "RECEIVE_SEARCH_CLIENTS":
            return {
                ...state,
                searchRequestState: RequestState.Succeeded,
                searchResult: action.searchResult
            };
        case "REJECT_SEARCH_CLIENTS":
            return {
                ...state,
                searchRequestState: RequestState.Failed
            };

        // Delete private label
        case "REQUEST_DELETE_CLIENT":
            return { ...state, deleteRequestState: RequestState.InProgress };
        case "REJECT_DELETE_CLIENT":
            return { ...state, deleteRequestState: RequestState.Failed };
        case "RECEIVE_DELETE_CLIENT":
            const newSearchResult: SearchResponse<ClientListItem> = {
                items: (state.searchResult?.items || []).filter(i => i.id !== action.id),
                total: (state.searchResult?.total || 0) - 1
            };

            return {
                ...state,
                searchResult: newSearchResult,
                deleteRequestState: RequestState.Succeeded
            };
        case "RESET_SEARCH_CLIENTS":
            return {
                ...state,
                ...unloadedState
            };
        default:
            return state;
    }
};
