import * as React from "react";

export enum AlertType {
    Default,
    Success,
    Info,
    Warning,
    Danger,
}

interface ComfirmAlertProps {
    title: string;
    description: string | React.ReactNode;
    onConfirm: (text: string) => void;
    onCancel?: () => void;
    confirmText?: string;
    cancelText?: string;
    type?: AlertType;
    prompt?: {
        text: string;
        placeholder?: string;
        label?: string;
    }
}

export const ConfirmAlert = (props: ComfirmAlertProps) => {
    const [text, setText] = React.useState(props.prompt?.text || "");
    const confirmButtonColor = {
        [AlertType.Default]: "btn-primary",
        [AlertType.Success]: "btn-success",
        [AlertType.Info]: "btn-info",
        [AlertType.Warning]: "btn-warning",
        [AlertType.Danger]: "btn-danger"
    }[props.type || AlertType.Default];

    return (
        <div style={{ maxWidth: 700 }} className="p-3 rounded-lg shadow bg-white border border-primary">
            <h3>{props.title}</h3>
            <p>{props.description}</p>

            {props.prompt && <div className="form-group">
                {props.prompt.label && <label>{props.prompt.label}</label>}
                <input
                    className="form-control"
                    value={text}
                    placeholder={props.prompt.placeholder}
                    onChange={e => setText(e.target.value)} />
            </div>}

            <div className="text-right">
                {!props.onCancel ? null : <button
                    className="btn btn-outline-primary"
                    onClick={props.onCancel}>{props.cancelText || "Cancel"}</button>}
                <button
                    className={`btn ml-3 ${confirmButtonColor}`}
                    onClick={() => props.onConfirm(text)}>{props.confirmText || "Confirm"}</button>
            </div>
        </div>
    );
};
