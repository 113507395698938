import * as React from "react";
import { FaBan, FaCheck, FaEdit, FaTrash } from "react-icons/fa";
import { Page, withCommonProps, withAuthProps, ConfirmAlert, AlertType, MessageBox, MessageBoxType } from "../Common";
import SearchableDataTable from "../Common/SearchableDataTable/SearchableDataTable";
import { IAuthProps, ApplicationState, RequestState } from "../../store";
import { ClientSearchState, ClientListItem } from "../../store/Clients/ClientsList/state";
import { actionCreators } from "../../store/Clients/ClientsList/actionCreators";
import { CommonState } from "../../store/Common/state";
import { connect } from "react-redux";
import { IDataTableColumn } from "react-data-table-component";
import { UncontrolledTooltip } from "reactstrap";
import { NavLink } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";

type ClientsPageProps =
    ClientSearchState &
    typeof actionCreators &
    IAuthProps &
    CommonState;

const ClientsPage = (props: ClientsPageProps) => {
    const columns: IDataTableColumn[] = [
        { name: "Id", maxWidth: "250px", cell: (row: ClientListItem) => row.clientId },
        { name: "Name", maxWidth: "400px", cell: (row: ClientListItem) => row.clientName },
        { name: "Allowed scopes", cell: (row: ClientListItem) => formatScopes(row.allowedScopes) },
        { name: "Status", maxWidth: "100px", cell: (row: ClientListItem) => (row.enabled
            ? <span><FaCheck className="mt-n1 text-success" /> Enabled</span>
            : <span><FaBan className="mt-n1 text-danger" /> Disabled</span>) },
        { name: "Actions", maxWidth: "100px", cell: (row: ClientListItem) => (
            <>
                <EditActionButton row={row} />
                <DeleteActionButton row={row} deleteRequestState={props.deleteRequestState} deleteClient={props.deleteClient} />
            </>
        ) }
    ];

    return (
        <Page title="Clients">
            {props.deleteRequestState === RequestState.InProgress && (
                <MessageBox
                    overlay
                    dismissible={false}
                    type={MessageBoxType.Information}
                    title="Deleting client"
                    description="Deleting client. Please wait..." />
            )}

            {props.deleteRequestState === RequestState.Succeeded && (
                <MessageBox
                    overlay
                    type={MessageBoxType.Success}
                    title="Client deleted"
                    description="Client has been deleted successfully." />
            )}

            {props.deleteRequestState === RequestState.Failed && (
                <MessageBox
                    overlay
                    type={MessageBoxType.Error}
                    title="Failed to delete client"
                    description="An error occurred when deleting client, please try again." />
            )}

            <SearchableDataTable
                columns={columns}
                search={props.searchClients}
                response={props.searchResult}
                displayText="client"
                state={props.searchRequestState} />
        </Page>
    );
};

export default withCommonProps(withAuthProps(connect(
    (state: ApplicationState) => ({ ...state.clientsList }),
    actionCreators
)(ClientsPage as any)));

const EditActionButton = (props: { row: ClientListItem }) => {
    return (
        <>
            <NavLink
                id={`client-edit-${props.row.id}`}
                className={"btn shadow-none text-dark btn-sm mt-n1"}
                to={`/clients/${props.row.id}`}>
                <FaEdit size={15} />
            </NavLink>
            <UncontrolledTooltip placement="top" target={`client-edit-${props.row.id}`}>Edit '{props.row.clientName}'</UncontrolledTooltip>
        </>
    );
};

const DeleteActionButton = (props: { row: ClientListItem, deleteRequestState: RequestState, deleteClient: (id: number) => void }) => {
    return (
        <>
            <button
                id={`delete-client-${props.row.id}`}
                className="btn shadow-none text-dark btn-sm mt-n1"
                type="button"
                disabled={props.deleteRequestState === RequestState.InProgress}
                onClick={e => {
                    e.preventDefault();
                    confirmAlert({
                        customUI: ({ onClose }) => <ConfirmAlert
                            title="Are you sure?"
                            type={AlertType.Danger}
                            description={(
                                <p>
                                    You are about to delete <strong>{props.row.clientName}</strong>. This action will remove all data associated with this client,
                                    and it cannot be undone. Are you sure you want to proceed?
                                </p>
                            )}
                            onCancel={onClose}
                            onConfirm={() => {
                                props.deleteClient(props.row.id);
                                onClose();
                            }}
                            confirmText="Yes, I am sure"
                            cancelText="Cancel" />
                    });
                }}>
                <FaTrash size={15} />
            </button>
            <UncontrolledTooltip placement="top" target={`delete-client-${props.row.id}`}>Delete {props.row.clientName}</UncontrolledTooltip>
        </>
    );
};

function formatScopes(scopes: string[]): string {
    const maxLength = 60;

    let stringLength = 0;
    for (let i = 0; i < scopes.length; i++) {
        for (let j = 0; j < scopes[i].length; j++) {
            if (++stringLength > maxLength) {
                return scopes.slice(0, i).join(", ") + ` and ${scopes.length - i} more`;
            }
        }
    }

    return scopes.join(", ");
}

