import * as React from "react";
import { FaExclamation, FaInfo, FaExclamationTriangle, FaDotCircle, FaCheck } from "react-icons/fa";
import { GrClose } from "react-icons/gr";
import { CSSProperties } from "styled-components";

export enum MessageBoxType {
    Error,
    Warning,
    Information,
    Success
}

interface SpinnerProps {
    title: string;
    description?: string;
    children?: React.ReactNode;
    className?: string;
    type?: MessageBoxType;
    overlay?: boolean;
    dismissible?: boolean;
}

export const MessageBox = (props: SpinnerProps) => {
    // Component state
    const [isClosed, setIsClosed] = React.useState(false);

    // Border color based on MessageBoxType.
    const borderColor = (() => {
        switch (props.type) {
            case MessageBoxType.Error: return "danger";
            case MessageBoxType.Warning: return "warning";
            case MessageBoxType.Information: return "primary";
            case MessageBoxType.Success: return "success";
            default: return "secondary";
        }
    })();

    // Header icon based on MessageBoxType.
    const icon = (() => {
        const iconClassName = `mt-n1 text-${borderColor}`;

        switch (props.type) {
            case MessageBoxType.Error: return <FaExclamation className={iconClassName} />;
            case MessageBoxType.Warning: return <FaExclamationTriangle className={iconClassName} />;
            case MessageBoxType.Information: return <FaInfo className={iconClassName} />;
            case MessageBoxType.Success: return <FaCheck className={iconClassName} />;
            default: return <FaDotCircle className={iconClassName} />;
        }
    })();

    // Render if message box is closed
    if (isClosed) {
        return <></>;
    }

    const overlayStyle: CSSProperties = {};
    if (props.overlay) {
        overlayStyle.position = "fixed";
        overlayStyle.top = "0";
        overlayStyle.left = "0";
        overlayStyle.width = "100%";
        overlayStyle.height = "100%";
        overlayStyle.backgroundColor = "rgba(255, 255, 255, 0.5)";
        overlayStyle.zIndex = 100;
    }

    const containerStyle: CSSProperties = {};
    if (props.overlay) {
        containerStyle.position = "absolute";
        containerStyle.top = "50%";
        containerStyle.left = "50%";
        containerStyle.transform = "translate(-50%, -50%)";
    }

    const dismissible = props.dismissible ?? true;

    // Render
    return (
        <div style={overlayStyle} className={props.className}>
            <div style={containerStyle} className={`bg-white border border-${borderColor} mx-auto p-3 rounded-lg shadow col-md-6 col-lg-5 col-xl-4`}>
                {dismissible && (
                    <button
                        onClick={() => setIsClosed(true)}
                        className="float-right btn btn-sm btn-default p-0">
                        <GrClose className="mt-n2" />
                    </button>
                )}
                <h5>{icon} {props.title}</h5>
                {!props.description ? null : <p className="mb-0">{props.description}</p>}
                {props.children}
            </div>
        </div>
    );
};
