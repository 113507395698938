import { reducer as commonReducer } from "./Common/reducer";
import { reducer as usersSearchReducer } from "./UserManagement/UsersSearch/reducer";
import { reducer as editUserReducer } from "./UserManagement/EditUser/reducer";
import { reducer as createUserReducer } from "./UserManagement/CreateUser/reducer";
import { reducer as userInfoReducer } from "./UserInformationView/reducer";
import { reducer as thermostatListReducer } from "./ThermostatsList/reducer";
import { reducer as thermostatDetailsReducer } from "./ThermostatDetails/reducer";
import { reducer as firmwareSearchReducer } from "./FirmwareUpdates/FirmwareSearch/reducer";
import { reducer as sessionSearchReducer } from "./FirmwareUpdates/SessionSearch/reducer";
import { reducer as sessionDetailsReducer } from "./FirmwareUpdates/SessionDetails/reducer";
import { reducer as firmwareThermostatsSearchReducer } from "./FirmwareUpdates/ThermostatSearch/reducer";
import { reducer as createFirmwareReducer } from "./FirmwareUpdates/CreateFirmware/reducer";
import { reducer as editFirmwareReducer } from "./FirmwareUpdates/EditFirmware/reducer";
import { reducer as editVisibilityReducer } from "./FirmwareUpdates/EditVisibility/reducer";
import { reducer as firmwareWizardReducer } from "./FirmwareUpdates/FirmwareWizard/reducer";
import { reducer as privateLabelsListReducer } from "./PrivateLabels/PrivateLabelsList/reducer";
import { reducer as privateLabelEditReducer } from "./PrivateLabels/PrivateLabelEdit/reducer";
import { reducer as privateLabelCreateReducer } from "./PrivateLabels/PrivateLabelCreate/reducer";
import { reducer as distributorsListReducer } from "./Distributors/DistributorsList/reducer";
import { reducer as distributorEditReducer } from "./Distributors/DistributorEdit/reducer";
import { reducer as distributorCreateReducer } from "./Distributors/DistributorCreate/reducer";
import { reducer as firmwareDetailsReducer } from "./FirmwareUpdates/FirmwareDetails/reducer";
import { reducer as retrySessionWizardReducer } from "./FirmwareUpdates/RetrySessionWizard/reducer";
import { reducer as retryThermostatWizardReducer } from "./FirmwareUpdates/RetryThermostatWizard/reducer";
import { reducer as emailTemplatesReducer } from "./EmailTemplates/reducer";
import { reducer as appVersionsReducer } from "./FirmwareUpdates/AppVersions/reducer";
import { reducer as pushNotificationsReducer } from "./PushNotifications/reducer";
import { reducer as clientSearchReducer } from "./Clients/ClientsList/reducer";
import { CommonState } from "./Common/state";
import { ThermostatsListState } from "./ThermostatsList/state";
import { UserState, reducer as oidcReducer } from "redux-oidc";
import { UsersSearchState } from "./UserManagement/UsersSearch/state";
import { EditUserState } from "./UserManagement/EditUser/state";
import { CreateUserState } from "./UserManagement/CreateUser/state";
import { ThermostatDetailsState } from "./ThermostatDetails/state";
import { FirmwareSearchState } from "./FirmwareUpdates/FirmwareSearch/state";
import { CreateFirmwareState } from "./FirmwareUpdates/CreateFirmware/state";
import { EditFirmwareState } from "./FirmwareUpdates/EditFirmware/state";
import { SessionSearchState } from "./FirmwareUpdates/SessionSearch/state";
import { PrivateLabelSearchState } from "./PrivateLabels/PrivateLabelsList/state";
import { PrivateLabelEditState } from "./PrivateLabels/PrivateLabelEdit/state";
import { PrivateLabelCreateState } from "./PrivateLabels/PrivateLabelCreate/state";
import { DistributorSearchState } from "./Distributors/DistributorsList/state";
import { DistributorEditState } from "./Distributors/DistributorEdit/state";
import { DistributorCreateState } from "./Distributors/DistributorCreate/state";
import { EditVisibilityState } from "./FirmwareUpdates/EditVisibility/state";
import { FirmwareWizardState } from "./FirmwareUpdates/FirmwareWizard/state";
import { FirmwareDetailsState } from "./FirmwareUpdates/FirmwareDetails/state";
import { RetrySessionWizardState } from "./FirmwareUpdates/RetrySessionWizard/state";
import { SessionDetailsState } from "./FirmwareUpdates/SessionDetails/state";
import { FirmwareThermostatSearchState } from "./FirmwareUpdates/ThermostatSearch/state";
import { RetryThermostatsWizardState } from "./FirmwareUpdates/RetryThermostatWizard/state";
import { EmailTemplatesState } from "./EmailTemplates/state";
import { AppVersionState } from "./FirmwareUpdates/AppVersions/state";
import { UserInformationViewState } from "./UserInformationView/state";
import SendPushNotificationsState from "./PushNotifications/state";
import { ClientSearchState } from "./Clients/ClientsList/state";

export * from "./authTypes";
export * from "./sharedTypes";

// The top-level state object
export interface ApplicationState {
    oidc?: UserState;
    common?: CommonState;
    usersSearch?: UsersSearchState;
    editUser?: EditUserState;
    createUser?: CreateUserState;
    userInfo?: UserInformationViewState;
    thermostatsList?: ThermostatsListState;
    thermostatDetails?: ThermostatDetailsState;
    firmwareSearch?: FirmwareSearchState;
    sessionSearch?: SessionSearchState;
    sessionDetails?: SessionDetailsState;
    firmwareThermostatsSearch?: FirmwareThermostatSearchState;
    createFirmware?: CreateFirmwareState;
    firmwareDetails?: FirmwareDetailsState;
    editFirmware?: EditFirmwareState;
    editVisibility?: EditVisibilityState;
    firmwareWizard?: FirmwareWizardState;
    retrySessionWizard?: RetrySessionWizardState;
    retryThermostatWizard?: RetryThermostatsWizardState;
    privateLabelsList?: PrivateLabelSearchState;
    clientsList?: ClientSearchState;
    privateLabelEdit?: PrivateLabelEditState;
    privateLabelCreate?: PrivateLabelCreateState;
    distributorsList?: DistributorSearchState;
    distributorEdit?: DistributorEditState;
    distributorCreate?: DistributorCreateState;
    emailTemplates?: EmailTemplatesState;
    appVersions?: AppVersionState;
    pushNotifications?: SendPushNotificationsState;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    oidc: oidcReducer,
    common: commonReducer,
    usersSearch: usersSearchReducer,
    editUser: editUserReducer,
    createUser: createUserReducer,
    userInfo: userInfoReducer,
    thermostatsList: thermostatListReducer,
    thermostatDetails: thermostatDetailsReducer,
    firmwareSearch: firmwareSearchReducer,
    sessionSearch: sessionSearchReducer,
    sessionDetails: sessionDetailsReducer,
    firmwareThermostatsSearch: firmwareThermostatsSearchReducer,
    createFirmware: createFirmwareReducer,
    firmwareDetails: firmwareDetailsReducer,
    editFirmware: editFirmwareReducer,
    editVisibility: editVisibilityReducer,
    firmwareWizard: firmwareWizardReducer,
    retrySessionWizard: retrySessionWizardReducer,
    retryThermostatWizard: retryThermostatWizardReducer,
    privateLabelsList: privateLabelsListReducer,
    privateLabelEdit: privateLabelEditReducer,
    privateLabelCreate: privateLabelCreateReducer,
    distributorsList: distributorsListReducer,
    distributorEdit: distributorEditReducer,
    distributorCreate: distributorCreateReducer,
    emailTemplates: emailTemplatesReducer,
    appVersions: appVersionsReducer,
    pushNotifications: pushNotificationsReducer,
    clientsList: clientSearchReducer
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
